import React from "react";
import BB_Website from "../Media/Projects/BB_Website.png";
import Innocom from "../Media/Projects/Innocom.png";
import Mindmate from "../Media/Projects/Mind_Mate.png";
import Quetor from "../Media/Projects/Quetor.png";
import "../Styles/Projects.css";

const Project = () => {
  return (
    <>
      <div className="project-heading">Our projects</div>
      <section className="project-section">
        <div className="project-sector">
          <a className="project">
            <img
              src={Quetor}
              alt="Question Paper Generator"
              className="project-logo"
            />
            <div className="project-details">
              <div className="project-name">Quetor</div>
              <div className="project-desc">
                A software solution to automate question paper creation,
                enhancing efficiency and assessment integrity.
              </div>
            </div>
          </a>
          <a className="project" href="https://innocom.tech/" target="window">
            <img
              src={Innocom}
              alt="Question Paper Generator"
              className="project-logo"
            />
            <div className="project-details">
              <div className="project-name">INNOCOM</div>
              <div className="project-desc">
                A website for INNOCOM - Innovative Community to foster student
                entrepreneurship and support their journey towards success.
              </div>
            </div>
          </a>
          <a className="project" href="https://bbapp.in/" target="window">
            <img
              src={BB_Website}
              alt="Question Paper Generator"
              className="project-logo"
            />
            <div className="project-details">
              <div className="project-name">BB App Website</div>
              <div className="project-desc">
                An elegant website for a versatile multi-service platform with
                seamless user experiences.
              </div>
            </div>
          </a>
          <a className="project coming-soon-project">
            <img
              src={Mindmate}
              alt="Question Paper Generator"
              className="project-logo"
            />
            <div className="project-details coming-soon">
              {/* <div className="project-name">Quetor</div>
              <div className="project-desc">A Question Paper Automator</div> */}
              <div className="project-desc coming-soon-text">
                Coming Soon...
              </div>
            </div>
          </a>
        </div>
      </section>
    </>
  );
};

export default Project;
